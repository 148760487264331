import { forwardRef, AnchorHTMLAttributes } from 'react';
import cx from 'classnames';
import { Icon } from 'components/Icon';
import { TIconName } from 'assets/js/variables/icon';
import { useGetLink } from '../LinkProvider';

import styles from './Link.module.scss';

export interface TextLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
  iconName?: TIconName;
  useTextColor?: boolean;
  block?: boolean;
  bold?: boolean;
  underline?: boolean;
}

export interface LinkProps extends Omit<TextLinkProps, 'iconName'> {}

export const TextLink = forwardRef<HTMLAnchorElement, TextLinkProps>(
  (
    { children, iconName, href, useTextColor, block, bold, underline, className, ...props },
    ref,
  ) => {
    const ActionLink = useGetLink();
    return (
      <ActionLink
        className={cx(styles.link, className, {
          [styles.hasIcon]: iconName,
          [styles.gray]: useTextColor,
          [styles.block]: block,
          [styles.bold]: bold,
          [styles.underline]: underline,
        })}
        href={href}
        ref={ref}
        {...props}
      >
        {iconName && <Icon iconName={iconName} className={styles.icon} aria-hidden="true" />}
        <span>{children}</span>
      </ActionLink>
    );
  },
);

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, href, useTextColor, block, bold, underline, className, ...props }, ref) => {
    const ActionLink = useGetLink();
    return (
      <ActionLink
        className={cx(styles.link, className, {
          [styles.gray]: useTextColor,
          [styles.block]: block,
          [styles.bold]: bold,
          [styles.underline]: underline,
        })}
        href={href}
        ref={ref}
        {...props}
      >
        {children}
      </ActionLink>
    );
  },
);
