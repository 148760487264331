import { FC, ReactNode, RefObject, useEffect, useRef } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import i18next from 'i18next';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ButtonPrimary } from '@timelog/ui-library';
import DigitalAdoptionPlugin from 'src/components/DigitalAdoptionPlugin';
import styles from './AppShell.module.scss';
import BrowserTitle from '../BrowserTitle';

interface AppShellProps {
  children: ReactNode;
  /** Title for the tab bar in browser */
  title?: string;
  /** ref to content element - usually a <main> tag */
  mainRefElement: RefObject<HTMLElement>;
}

// This component is used for wrapping each layout we have
// to ensure that correct things a applied in <head>
// and that we have a "skip to content" button.
export const AppShell: FC<AppShellProps> = ({ children, title, mainRefElement }) => {
  const { t } = useTranslation('header');
  const ref = useRef<HTMLSpanElement>(null);
  const { pathname } = useLocation();

  // remove once react-router accessibility issue is fixed
  // https://github.com/ReactTraining/react-router/issues/5210
  useEffect(() => {
    ref.current?.focus();
  }, [pathname]);

  const skipToMainContent = () => {
    if (mainRefElement) {
      setTimeout(() => {
        if (mainRefElement.current) {
          mainRefElement.current.setAttribute('tabIndex', '-1');
          mainRefElement.current.focus();
        }
      }, 100);
    }
  };

  return (
    <HelmetProvider>
      <Helmet htmlAttributes={{ lang: i18next.language }} />
      <BrowserTitle title={title} />
      <DigitalAdoptionPlugin />
      <div className={styles.App}>
        <span ref={ref} tabIndex={-1} data-automation-id="AppShellInitialFocusPoint" />
        <ButtonPrimary
          onClick={skipToMainContent}
          data-skip-link
          data-automation-id="AppShellSkipToContentButton"
        >
          {t('SkipToContentButton')}
        </ButtonPrimary>

        {children}
      </div>
    </HelmetProvider>
  );
};
